import * as React from "react";
import { Text, Icon } from "@chakra-ui/core";
import { SqlJs } from "sql.js/module";

interface SuccessStatusProps {
  duration: number;
  results: SqlJs.QueryResults;
}

export const SuccessStatus = ({ duration, results }: SuccessStatusProps) => {
  return (
    <Text color="grey.500" flex="1">
      <Icon
        name="check-circle"
        mr={3}
        size="5"
        verticalAlign="text-top"
        color="green.500"
      />
      Query ran in {duration}ms returning {results.values.length} results
    </Text>
  );
};

interface ErrorStatusProps {
  error: Error;
}

export const ErrorStatus = ({ error }: ErrorStatusProps) => {
  const msg = error.message.charAt(0).toUpperCase() + error.message.slice(1);
  return (
    <Text color="red.500" flex="1">
      <Icon
        name="warning"
        mr={3}
        size="5"
        verticalAlign="text-top"
        color="red.500"
      />
      {msg}
    </Text>
  );
};
