import { theme } from "@chakra-ui/core";

export default {
  ...theme,
  fonts: {
    ...theme.fonts,
    heading: `"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
    body: `"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`,
    mono: "SFMono-Medium, monospace",
  },
  colors: {
    ...theme.colors,
    black: "#1A202C",
    deepBlue: "#464657",
    vividBlue: "#1D1DE8",
    punkPink: "#F13785",
    lightText: "#727287",
    lessLightBackground: "#EDEBEC",
    lightBackground: "#f8f5f6",
  },
  shadows: {
    ...theme.shadows,
    buttonShadow: "0px 2px 6px rgba(78, 29, 50, 0.1)",
    cardShadow: "0px 4px 21px rgba(78, 29, 50, 0.14)",
  },
};
