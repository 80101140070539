import * as React from "react";
import {
  Button,
  Box,
  Flex,
  ButtonGroup,
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionIcon,
  AccordionPanel,
} from "@chakra-ui/core";
import * as sqlFormatter from "sql-formatter";
import { SqlJs } from "sql.js/module";
import { DatabaseClient, Schema } from "../database";
import { QueryEditor } from "./query-editor";
import { QueryResults } from "./query-results";
import { SuccessStatus, ErrorStatus } from "./query-status";

export interface ExploreProps {
  database: DatabaseClient;
}

const defaultQuery = `SELECT
  *
FROM
  __TABLE__
LIMIT
  100`;

export const Explore = (props: ExploreProps) => {
  const [schema, setSchema] = React.useState<Schema>(null);
  const [query, setQuery] = React.useState<string>(defaultQuery);
  const [results, setResults] = React.useState<SqlJs.QueryResults>(null);
  const [error, setError] = React.useState<Error>(null);
  const [queryDuration, setQueryDuration] = React.useState<number>(null);

  React.useEffect(() => {
    (async () => {
      const schema = await props.database.schema();
      setSchema(schema);
      setQuery(defaultQuery.replace("__TABLE__", Object.keys(schema)[0]));
    })();
  }, []);

  const formatQuery = () => {
    setQuery(sqlFormatter.format(query));
  };

  const runQuery = async () => {
    const queryStart = new Date();
    try {
      const response = await props.database.exec({ sql: query });
      if (!response.results || response.results.length < 1) {
        throw new Error("query returned no results");
      }
      setResults(response.results[0]);
      setQueryDuration(new Date().getTime() - queryStart.getTime());
      setError(null);
    } catch (e) {
      setResults(null);
      setQueryDuration(null);
      setError(e);
      console.log(e);
    }
  };

  if (!schema) {
    return <span>Loading...</span>;
  }

  return (
    <Flex flexDir="column" height="96vh">
      <Flex flex="1" overflowY="hidden" paddingBottom={4}>
        <Box flex="1" overflowY="scroll">
          <Accordion allowToggle mr="5">
            {Object.keys(schema).map((table) => (
              <AccordionItem key={table}>
                <AccordionHeader fontSize="sm" fontFamily="mono">
                  <Box
                    title={table}
                    flex="1"
                    textAlign="left"
                    style={{ textOverflow: "ellipsis" }}
                    overflowX="hidden"
                  >
                    {table}
                  </Box>
                  <AccordionIcon />
                </AccordionHeader>
                <AccordionPanel pb={2}>
                  {schema[table].map((col, i) => (
                    <Box fontSize="xs" fontFamily="mono" key={i}>
                      {col.name} ({col.type.toLowerCase()})
                    </Box>
                  ))}
                </AccordionPanel>
              </AccordionItem>
            ))}
          </Accordion>
        </Box>
        <Flex flex="3" flexDir="column">
          <QueryEditor
            query={query}
            schema={schema}
            onQueryChange={setQuery}
            onTrigger={runQuery}
          />
          <Flex justify="flex-end" align="center" py="2">
            {queryDuration !== null && (
              <SuccessStatus duration={queryDuration} results={results} />
            )}
            {error !== null && <ErrorStatus error={error} />}
            <ButtonGroup spacing={2}>
              <Button variant="outline" onClick={formatQuery}>
                Format
              </Button>
              <Button
                variant="solid"
                variantColor="blue"
                onClick={runQuery}
                title="Run (Cmd+R)"
              >
                Run
              </Button>
            </ButtonGroup>
          </Flex>
        </Flex>
      </Flex>

      <Box flex="1">{results && <QueryResults results={results} />}</Box>
    </Flex>
  );
};
