import * as React from "react";
import * as codemirror from "codemirror";
import { Controlled as ControlledCodeMirror } from "react-codemirror2";
import "codemirror/mode/sql/sql";
import "codemirror/addon/hint/show-hint";
import "codemirror/addon/hint/sql-hint";
import "codemirror/lib/codemirror.css";
import "codemirror/addon/hint/show-hint.css";
import "codemirror/theme/ayu-mirage.css";
import { Schema } from "../database";
import { Global } from "@emotion/core";
import { useTheme } from "@chakra-ui/core";

export interface QueryEditorProps {
  query: string;
  schema: Schema;
  onQueryChange: (query: string) => void;
  onTrigger: () => void;
}

export const QueryEditor = (props: QueryEditorProps) => {
  const options = {
    mode: "text/x-sqlite",
    theme: "ayu-mirage",
    autofocus: true,
    lineNumbers: true,
    hintOptions: {
      hint: codemirror.hint.sql,
      tables: Object.fromEntries(
        Object.entries(props.schema).map(([table, cols]) => [
          table,
          cols.map((c) => c.name),
        ])
      ),
    },
    extraKeys: {
      "Cmd-Enter": () => {
        props.onTrigger();
      },
      Tab: (cm: any) => cm.execCommand("indentMore"),
      "Shift-Tab": (cm: any) => cm.execCommand("indentLess"),
      "Ctrl-Space": "autocomplete",
    },
  };

  const theme = useTheme();
  return (
    <>
      <Global
        styles={{
          ".react-codemirror2": {
            flex: 1,
            overflow: "hidden",
          },
          ".CodeMirror": {
            borderRadius: theme.radii.lg,
            fontFamily: theme.fonts.mono,
            fontSize: theme.fontSizes.sm,
            fontWeight: theme.fontWeights.normal,
            lineHeight: "1.2rem",
            padding: theme.space["2"],
            height: "100%",
          },
          ".CodeMirror-hint": {
            fontFamily: theme.fonts.mono,
            fontWeight: theme.fontWeights.bold,
          },
        }}
      />
      <ControlledCodeMirror
        value={props.query}
        options={options}
        onBeforeChange={(_editor, _data, value) => {
          props.onQueryChange(value);
        }}
      />
    </>
  );
};
