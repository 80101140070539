import * as React from "react";
import * as ReactDOM from "react-dom";
import { ThemeProvider, CSSReset } from "@chakra-ui/core";
import theme from "./theme";
import { App } from "./components/app";
import "./index.css";
import { Global, css } from "@emotion/core";

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <CSSReset />
    <Global
      styles={css`
        html {
          -webkit-font-smoothing: unset;
        }
      `}
    />
    <App />
  </ThemeProvider>,
  document.getElementById("app")
);
