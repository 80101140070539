import * as React from "react";
import ReactDataGrid from "react-data-grid";
import "react-data-grid/dist/react-data-grid.css";
import { AutoSizer } from "react-virtualized";
import styled from "@emotion/styled";
import { SqlJs } from "sql.js/module";
import { Global } from "@emotion/core";
import { useTheme } from "@chakra-ui/core";

export interface QueryResultsProps {
  results: SqlJs.QueryResults | null;
}

const ResultsGrid = styled(ReactDataGrid)`
  font-family: monospace;
  height: 100%;
  border-radius: 20px;
`;

export const QueryResults = React.memo((props: QueryResultsProps) => {
  if (!props.results) {
    return <div>No results</div>;
  }

  const { results } = props;
  const columns = results.columns.map((c) => ({
    key: c,
    name: c,
    resizable: true,
  }));
  const rows = results.values.map((r) =>
    r.reduce((acc: { [key: string]: string }, val, i) => {
      const col = results.columns[i].toString();
      acc[col] = val.toString();
      return acc;
    }, {})
  );

  const theme = useTheme();

  return (
    <>
      <Global
        styles={{
          ".rdg": {
            borderRadius: theme.radii.lg,
            fontFamily: theme.fonts.mono,
          },
        }}
      />
      <AutoSizer>
        {({ height, width }) => (
          <ResultsGrid
            columns={columns}
            rows={rows}
            width={width}
            height={height}
          />
        )}
      </AutoSizer>
    </>
  );
});
