import { deserializeError } from "serialize-error";
import * as rpc from "./rpc";

interface PendingRequest {
  resolve?: (value?: any) => void;
  reject?: (reason?: any) => void;
}

interface PendingRequests {
  [id: string]: PendingRequest;
}

interface ColumnDefinition {
  name: string;
  type: string;
  pk: boolean;
}

export interface Schema {
  [table: string]: ColumnDefinition[];
}

const schemaQuery = `
SELECT
  sqlite_master.name AS table_name,
  table_info.name AS column_name,
  table_info.type AS column_type,
  table_info.pk AS column_pk
FROM
  sqlite_master
JOIN
  pragma_table_info(sqlite_master.name) AS table_info
ORDER BY
  sqlite_master.name,
  table_info.name
`;

export class DatabaseClient {
  nextRequestId: number;
  worker: Worker;
  pendingRequests: PendingRequests;

  constructor(worker: Worker) {
    this.nextRequestId = 1;
    this.worker = worker;
    this.pendingRequests = {};
    this.worker.addEventListener("message", this.handleMessage.bind(this));
  }

  load(req: rpc.LoadRequest): Promise<any> {
    return this.request("load", req);
  }

  exec(req: rpc.ExecRequest): Promise<rpc.ExecResponse> {
    return this.request("exec", req);
  }

  async schema(): Promise<Schema> {
    const rsp = await this.exec({ sql: schemaQuery });
    return rsp.results[0].values.reduce((acc: Schema, row) => {
      const tableName = row[0].valueOf() as string;
      const cols = acc[tableName] || [];
      const col: ColumnDefinition = {
        name: row[1].valueOf() as string,
        type: row[2].valueOf() as string,
        pk: Boolean(row[3].valueOf() as number),
      };
      return { ...acc, [row[0].toString()]: [...cols, col] };
    }, {});
  }

  handleMessage(msg: MessageEvent) {
    const requestId = msg.data.requestId;
    const pendingReq = this.pendingRequests[requestId];
    if (!pendingReq) {
      throw new Error(`Unmatched query response ${requestId}`);
    }

    delete this.pendingRequests[requestId];
    if (msg.data.error) {
      pendingReq.reject(deserializeError(msg.data.error));
    } else {
      pendingReq.resolve(msg.data.payload);
    }
  }

  request(action: rpc.Action, payload: any): Promise<any> {
    const id = this.nextRequestId++;
    const pendingReq: PendingRequest = {};
    const promise = new Promise((resolve, reject) => {
      pendingReq.resolve = resolve;
      pendingReq.reject = reject;
    });
    this.pendingRequests[id] = pendingReq;
    this.worker.postMessage({
      requestId: id,
      action: action,
      payload: payload,
    });
    return promise;
  }
}
