import * as React from "react";
import { useHistory } from "react-router-dom";
import { Input, Button } from "@chakra-ui/core";

// When given a github.com URL, rewrite it to the equivalent raw.githubusercontent.com URL.
// While the original URL does redirect, the redirect doesn't have the CORS headers so it'll fail.
const rewriteGitHubUrl = (originalUrl: string): string => {
  const url = new URL(originalUrl);
  console.log(originalUrl);
  if (url.hostname === "github.com" && url.pathname.includes("/blob/")) {
    console.log("rewriting");
    url.hostname = "raw.githubusercontent.com";
    url.pathname = url.pathname.replace("/blob/", "/");
    url.search = "";
  }
  console.log(url.href);
  return url.href;
};

export const Home = () => {
  const [databaseUrl, setDatabaseUrl] = React.useState("");
  const handleUrlChange = (e: React.FormEvent<HTMLInputElement>) =>
    setDatabaseUrl(e.currentTarget.value);

  let history = useHistory();

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const url = rewriteGitHubUrl(databaseUrl);
    history.push(`/explore/${encodeURIComponent(url)}/`);
  };

  return (
    <>
      <h2>Datico</h2>
      <form onSubmit={handleSubmit}>
        <Input
          value={databaseUrl}
          onChange={handleUrlChange}
          placeholder="Sqlite database URL"
          size="sm"
        />
        <Button mt={4} variantColor="blue" type="submit">
          Submit
        </Button>
      </form>
    </>
  );
};
