import * as React from "react";
import { DatabaseClient } from "./database";

export interface DatabaseContainerProps {
  dbPath: string;
  children?: (database: DatabaseClient) => React.ReactNode;
}

export const DatabaseContainer = (props: DatabaseContainerProps) => {
  const [database, setDatabase] = React.useState<DatabaseClient>(null);

  React.useEffect(() => {
    (async () => {
      const dbWorker = new Worker("./worker", { type: "module" });
      const dbClient = new DatabaseClient(dbWorker);
      await dbClient.load({ databasePath: props.dbPath });
      setDatabase(dbClient);
    })();
  }, []);

  if (!database) {
    return <div>Loading...</div>;
  }
  return <>{props.children(database)}</>;
};
