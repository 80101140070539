import * as React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
} from "react-router-dom";
import styled from "@emotion/styled";
import { Home } from "./home";
import { Explore } from "./explore";
import { DatabaseClient } from "../database";
import { DatabaseContainer } from "../database-container";

const AppContainer = styled.div`
  font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica,
    Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;

  input {
    font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Helvetica,
      Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  }

  padding: 2vh;
`;

const WrappedExploreTemp = () => {
  const { databasePath } = useParams();
  return (
    <DatabaseContainer dbPath={decodeURIComponent(databasePath)}>
      {(database: DatabaseClient) => <Explore database={database} />}
    </DatabaseContainer>
  );
};

export const App = () => {
  return (
    <Router>
      <AppContainer>
        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
          <Route
            path="/explore/:databasePath"
            children={<WrappedExploreTemp />}
          />
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </AppContainer>
    </Router>
  );
};
